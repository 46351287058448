export const capitalize = (str) => {
  if (!str) return '';
  return str?.charAt?.(0)?.toUpperCase() + str?.slice(1);
};

export const formatPrice = (num) => {
  if (num === undefined || num === null) return 0;
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const compareDates = (date1, date2) => date1.getTime() < date2.getTime();

export default {
  capitalize,
  formatPrice,
  compareDates,
};
