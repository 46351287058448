import React from 'react';
import { Nav, FAQAccordion } from '@components';
import grid from '../../assets/grid.svg';
import mockup from '../../assets/images/mockup.png';
import mock from '../../assets/images/mock.png';
import { images } from '../../data';
import Slider from 'react-infinite-logo-slider';
import telegram from '../../assets/logos/telegram.svg';
import { Messages } from 'iconsax-react';
import { ChatModal } from '@home_components';

const featuresList = [
  {
    title: 'Decentralized',
    description:
      'Webtrade is a decentralized exchange that allows users to trade cryptocurrencies, tokens, and other digital assets. It is built on the Ethereum blockchain and uses smart contracts to facilitate trading without the need for a centralized authority.',
  },
  {
    title: 'Secure',
    description:
      'Webtrade uses the latest security technology to ensure that your funds and personal information are safe. We take security very seriously and have implemented a number of measures to protect your assets.',
  },
  {
    title: 'Fast',
    description:
      'Webtrade is designed to be fast and efficient, so you can make trades quickly and easily. Our platform is built on the latest technology to ensure that trades are executed as quickly as possible.',
  },
  {
    title: 'Low fees',
    description:
      'Webtrade charges a small fee for each trade. The fee is based on the size of the trade and is used to cover the cost of running the exchange. We believe in transparent pricing and have no hidden fees.',
  },
  {
    title: 'Trade a wide range of assets',
    description:
      'Webtrade supports a wide range of assets, including cryptocurrencies, tokens, and other digital assets. You can trade all of these assets on a single platform, making it easy to diversify your portfolio.',
  },
  {
    title: 'AI and Machine Learning',
    description:
      'Webtrade is powered with the latest AI and Machine Learning technologies to give you the best trading experience. Our algorithms analyze market data in real-time to help you make informed trading decisions.',
  },
];

const faq_list = [
  {
    question: 'What is Webtrade?',
    answer:
      'Webtrade is a decentralized exchange that allows users to trade cryptocurrencies, tokens, and other digital assets. It is built on the Ethereum blockchain and uses smart contracts to facilitate trading without the need for a centralized authority.',
  },
  {
    question: 'How do I get started?',
    answer:
      'To get started, you will need to create an account and deposit funds. Once you have funds in your account, you can start trading.',
  },
  {
    question: 'What assets can I trade?',
    answer:
      'Webtrade supports a wide range of assets, including cryptocurrencies, tokens, and other digital assets.',
  },
  {
    question: 'How do I deposit and withdraw funds?',
    answer:
      'You can deposit and withdraw funds using a variety of methods, including bank transfer, credit card, and cryptocurrency.',
  },
  {
    question: 'What are the fees?',
    answer:
      'Webtrade charges a small fee for each trade. The fee is based on the size of the trade and is used to cover the cost of running the exchange.',
  },
];

const CardItem = ({ title, description }) => {
  return (
    <div className="flex flex-col p-7 border-[1px] border-[#3b3b3b] rounded-2xl bg-[#000000] backdrop-blur-[2rem] gap-5">
      <h1 className="text-xl">{title}</h1>
      <p className="text-[#a3a3a3] text-sm font-extralight leading-[160%]">
        {description}
      </p>
    </div>
  );
};

const Home = () => {
  const [openChat, setOpenChat] = React.useState(false);
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  React.useEffect(() => {
    if (openChat) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [openChat]);

  return (
    <div className="overflow-x-hidden">
      {openChat && (
        <div className="fixed bottom-4 right-4 z-50">
          <ChatModal closeChat={() => setOpenChat(false)} />
        </div>
      )}
      {/* floating chat fab */}
      <div className="fixed bottom-4 right-4 md:bottom-12 md:right-12 z-[20]">
        <button
          className="bg-[#fff] text-black px-4 py-4 rounded-full cursor-pointer flex items-center gap-4 pr-6"
          onClick={() => setOpenChat(true)}
        >
          <Messages size={24} color="#000000" />
          AI Assisstant
        </button>
      </div>

      <div className="fade w-[80vw] sm:w-[50vw] aspect-square rounded-full absolute z-0 left-[10vw] sm:left-[25vw] -top-[25vw] blur-[10rem]"></div>
      <Nav />
      <img
        src={grid}
        alt=""
        className="h-[100vh] w-[100vw] object-cover opacity-[.15] fixed z-[2] top-0"
      />
      <div className="w-[100vw] h-[100vh] absolute bg-transparent flex flex-col items-center justify-center z-[3]">
        <div className="flex flex-col items-center justify-center w-[100vw] gap-[2rem] px-4">
          <div className="px-7 py-4 border-2 border-[#ffffff44] rounded-full bg-[#ffffff22] backdrop-blur-[2rem]">
            <h1 className="text-sm">TRADE SMARTER, NOT HARDER</h1>
          </div>
          <h1 className="text-white text-center text-4xl md:text-7xl leading-[140%] max-w-[80%] font-extralight">
            The next generation crypto trading platform that you are looking for
          </h1>
          <p className="text-center font-extralight text-lg md:text-xl">
            Next webtrade is powered with the latest AI and Machine Learning
            technologies
            <br className="hidden md:block" />
            to give you the best trading experience!
          </p>
          {/* <Banner images={images} /> */}
          <div className="flex flex-col md:flex-row gap-6">
            <button
              className="bg-[#fff] text-black px-2 py-2 rounded-full cursor-pointer flex items-center gap-2 pr-3 mt-[2rem]"
              onClick={() =>
                (window.location.href = 'https://t.me/WebTradeGlobal')
              }
            >
              <img src={telegram} alt="" className="h-[2rem]" />
              Join us on Telegram
            </button>
          </div>
        </div>
      </div>
      <div className="w-[100vw] overflow-scroll absolute bottom-10 z-[10]">
        <Slider duration={40} pauseOnHover={true} blurBorders={false}>
          {images.map(({ id, image }) => (
            <Slider.Slide key={id}>
              <img src={image} alt={id} className="w-[4rem] md:w-[7rem]" />
            </Slider.Slide>
          ))}
        </Slider>
      </div>
      <div className="absolute z-[2] top-[100vh] pt-[5rem]">
        <div className="flex flex-col md:flex-row items-center justify-between px-[5vw] md:px-[10vw] pb-[5vh] md:pb-[15vh] gap-[5vw] h-[100vh]">
          <img
            src={mock}
            alt=""
            className="h-[50vh] md:h-[60vh] w-auto object-contain flex-1"
          />
          <p className="text-white font-extralight text-[1.5rem] md:text-[3.5rem] text-left leading-[130%]">
            Webtrade is a decentralized exchange platform that allows users to
            trade cryptocurrencies, tokens, and other digital assets. It is
            built on the Ethereum blockchain and uses AI and Smart Contracts to
            facilitate trading without the need for a centralized authority.
          </p>
        </div>
        <p className="text-white font-extralight text-2xl md:text-6xl leading-[130%] text-left px-[5vw] md:px-[10vw] pb-[3rem]">
          Why using webtrade?
        </p>
        {/* <Slider width="10rem" duration={60} pauseOnHover={true} blurBorders={false}> */}
        <div className="grid grid-cols-1 md:grid-cols-3 px-[5vw] md:px-[10vw] gap-5">
          {featuresList.map((feature, index) => (
            <CardItem
              key={index}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
        {/* faq accordions */}
        <div className="w-full px-[5vw] md:px-[10vw]  my-[10vh]">
          <p className="text-white font-extralight text-2xl md:text-6xl leading-[130%] text-left pb-[3rem]">
            Frequently Asked Questions
          </p>
          <FAQAccordion faqs={faq_list} />
        </div>
        <div className="w-full flex flex-col items-center">
          <p className="text-white font-extralight text-2xl md:text-6xl leading-[130%] text-center">
            What are you waiting for?
            <br />
            Start trading now!
          </p>
          <button
            className="bg-[#fff] text-black px-6 py-3 rounded-full cursor-pointer flex items-center gap-2 mt-[2rem]"
            onClick={() => (window.location.href = '/dashboard/?view=trading')}
          >
            Start Trading
          </button>
          <img src={mockup} className="w-[90vw] md:w-[80vw]" />
        </div>
        <footer className="w-full flex items-center justify-center pt-[10vh] pb-[1rem]">
          <p>2024 &copy; Webtrade&trade;</p>
        </footer>
      </div>
    </div>
  );
};

export default Home;
