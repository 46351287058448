import React from 'react';

const useUser = (id) => {
  const server_url = 'https://webtrade.onrender.com';
  const route = '/users';
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const getUser = async () => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}/${id}`);
    const data = await response.json();
    setUser(data);
    setLoading(false);
  };

  const updateUser = async (user) => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const data = await response.json();
    setUser(data);
    setLoading(false);
  };

  React.useEffect(() => {
    getUser();
    const interval = setInterval(() => {
      getUser();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return { user, updateUser, loading };
};

export default useUser;
