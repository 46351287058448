import React from 'react';
import { capitalize } from '@utils/utils.astro';
import { Menu, Copy } from 'react-feather';

const MobileNav = ({ mainView, open }) => {
  return (
    <nav className="dashboard_main_view_nav">
      <section className="left">
        <h2 className="title">{capitalize(mainView)}</h2>
      </section>
      <section className="right" onClick={open}>
        <Menu size={30} color="white" />
      </section>
    </nav>
  );
};

const NavBar = ({ mainView, user, open }) => {
  const [animation, setAnimation] = React.useState(false);
  const copyHash = () => {
    navigator.clipboard.writeText(`0x${user?._id?.slice(3, 9)?.toUpperCase()}`);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 2500);
  };

  return window.innerWidth > 900 ? (
    <nav className="dashboard_main_view_nav">
      <div className={animation ? 'success_copy' : 'hidden'}>
        <p>USER ID copied!</p>
      </div>
      <section className="left">
        <h2 className="title">{capitalize(mainView)}</h2>
      </section>
      <section className="right">
        {/* <div className="search_container">
          <span className="search_icon_container">
            <img
              src="/dashboard/search.svg"
              alt="search"
              className="search_icon"
            />
          </span>
          <input
            type="search"
            name="search"
            className="search_input"
            placeholder="Search your coins..."
          />
        </div> */}
        <div className="notification_container">
          <img
            src="/dashboard/notification.svg"
            alt="notification"
            className="notification_icon"
          />
          <img
            src="/dashboard/notification_badge.svg"
            alt="notification_badge.svg"
            className="notification_badge"
          />
        </div>
        <div className="profile_container">
          <h3 className="user_name">{user?.name}</h3>
          <div className="hash_container" onClick={copyHash}>
            <h3 className="hash_text">
              USER ID: 0x{user?._id?.slice(3, 9)?.toUpperCase()}
            </h3>
            <Copy size={18} color="#ffffff" />
          </div>
          <img src="/avatar.jpeg" alt="profile picture" className="user_img" />
          {/* <img src="/dashboard/drop.svg" alt="drop down" /> */}
        </div>
      </section>
    </nav>
  ) : (
    <MobileNav mainView={mainView} open={open} />
  );
};

export default NavBar;
