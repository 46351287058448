import React from 'react';
import { Nav } from '@components';
import { Eye, EyeOff } from 'react-feather';
import { useAuth } from '@hooks/useAuth';
import { LoaderContext } from '../../App';
import { LoaderOverlay } from '@components';

const Signup = () => {
  document.title = 'Webtrade - Signup';

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [nameError, setNameError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);

  const [registerStatus, setRegisterStatus] = React.useState(null);

  const { checkUsername, checkEmail, signup, user, loading } = useAuth();

  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  if (user) {
    return window.location.replace('/dashboard/?view=dashboard');
  }

  const sign = async (e) => {
    e.preventDefault();

    setNameError(null);
    setEmailError(null);
    setPasswordError(null);
    // Validating name
    if (!name || name.trim().length === 0) {
      setNameError('Name is required');
    }

    // Validating email
    if (!email || email.trim().length === 0) {
      setEmailError('Email is required');
    } else if (!email.includes('@')) {
      setEmailError('Email is invalid');
    }

    // Validating password
    if (!password || password.length === 0)
      return setPasswordError('Password is required');
    else if (password.length < 8)
      return setPasswordError('Password must be at least 8 characters');
    else if (!/\d/.test(password))
      return setPasswordError('Password must contain at least one number');
    else if (!/[a-z]/.test(password))
      return setPasswordError(
        'Password must contain at least one lowercase letter',
      );
    else if (!/[A-Z]/.test(password))
      return setPasswordError(
        'Password must contain at least one uppercase letter',
      );
    else if (!/[^A-Za-z0-9]/.test(password))
      return setPasswordError('Password must contain at least one symbol');

    // Checking if username is available
    const nameCheck = await checkUsername(name);
    if (nameCheck.error) return setNameError(nameCheck.error);

    // Checking if email is available
    const emailCheck = await checkEmail(email);
    if (emailCheck.error) return setEmailError(emailCheck.error);

    // If everything is valid, proceed with registration
    const data = await signup(name, email, password);
    if (data?.error || data?.message)
      return setRegisterStatus(data?.error ? data.error : data.message);
    window.location.replace('/dashboard/?view=dashboard');
  };

  return (
    <section className="auth_page">
      {loading && <LoaderOverlay />}
      <Nav hide="signup" />
      <main className="auth_container">
        <div className="auth_title">
          <h1 className="auth_title">Create a new account</h1>
        </div>
        <form className="auth_form">
          <div className="auth_form_group">
            <label htmlFor="email">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Enter your name"
            />
            {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="auth_form_group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter your email"
            />
            {emailError && <p className="error">{emailError}</p>}
          </div>
          <div className="auth_form_group">
            <label htmlFor="password">Password</label>
            <div className="password_input">
              <input
                type={passwordVisible ? 'text' : 'password'}
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="off"
                autoSave="off"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Enter your password"
              />
              <div className="eye_container">
                {passwordVisible ? (
                  <EyeOff
                    style={{ cursor: 'pointer' }}
                    color="rgba(119, 237, 145, .2)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(false)}
                  />
                ) : (
                  <Eye
                    style={{ cursor: 'pointer' }}
                    color="rgba(119, 237, 145, 1)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(true)}
                  />
                )}
              </div>
            </div>
            {passwordError && <p className="error">{passwordError}</p>}
          </div>
          {registerStatus && <p className="error">{registerStatus}</p>}
          <button onClick={sign}>Sign up</button>
        </form>
      </main>
    </section>
  );
};

export default Signup;
