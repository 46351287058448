import React from 'react';
import { useAuth } from '@hooks/useAuth';
import { LoaderContext } from '../../../App';
import { LoaderOverlay } from '@components';

const Profile = () => {
  const [auth, setAuth] = React.useState(true);
  const { checkAuth, logout, loading } = useAuth();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const check = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAuth(false);
      window.location.replace('/auth/signin');
    }
    return setAuth(true);
  };

  React.useEffect(() => {
    check();
    if (!auth) return window.location.replace('/auth/signin');
  }, []);

  React.useEffect(() => {
    if (!auth) window.location.replace('/auth/signin');
  }, [auth]);

  return (
    <div>
      {loading && <LoaderOverlay />}
      <button onClick={check}>check auth: {auth && 'okeh'}</button>
      <button onClick={logout}>logout</button>
    </div>
  );
};

export default Profile;
