import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonCard = ({ count }) => {
  const skeletonMap = (() => {
    let arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(i);
    }
    return arr;
  })();

  return (
    // <div className="skeleton_cards">
    skeletonMap?.map((item) => (
      <Skeleton
        key={item}
        borderRadius={60}
        highlightColor="#909093"
        baseColor="#212325"
        enableAnimation={true}
        className="top_coin_card"
        style={{ backgroundColor: '#212325', marginBottom: 0 }}
      />
    ))
    // </div>
  );
};

export default SkeletonCard;
