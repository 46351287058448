import React, { useEffect } from 'react';
import {
  TradeCard,
  TopCoinCard,
  TimelineSelector,
  TransactiosHistory,
} from '../components';

import useTrade, { useTradeHistory } from '@hooks/useTrade';
import useUser from '@hooks/useUser';
import { LoaderContext } from '../../../App';
import { LoaderOverlay } from '@components';

import { X } from 'react-feather';

const TradeCardBullet = ({
  title,
  subtitle,
  value,
  setMax,
  currency = '',
  style,
  min = 0,
  placeholder = 'Enter limit price',
}) => {
  const inputRef = React.useRef(null);
  return (
    <div className="quick_trade_card" style={{ ...style }}>
      <p className="title">{title}</p>
      {setMax ? (
        <div className="input_container">
          <input
            ref={inputRef}
            type="number"
            name="num"
            id="num_input"
            placeholder={placeholder}
            min={min}
            onChange={(e) => setMax(e.target.value)}
          />
          <p>$</p>
        </div>
      ) : (
        <p className="subtitle" style={{ color: '#fff' }}>
          {value !== null ? currency + value : subtitle}
        </p>
      )}
    </div>
  );
};

export const MobileTrading = ({
  coins,
  coin,
  setCoin,
  changeTimeline,
  setChangeTimeline,
}) => {
  const {
    activeButton,
    setActiveButton,
    coinSelector,
    setCoinSelector,
    timer,
    setTimer,
    max,
    setMax,
    selector,
    times,
    trade,
  } = useTrade(coins, coin, setCoin);

  const { setLoading } = React.useContext(LoaderContext);

  useEffect(() => {
    if (!coins.length) {
      setLoading(true);
    } else setLoading(false);
  }, [coins]);

  const [search, setSearch] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const handleSearch = (e) => {
    if (e.target.value === '') {
      setIsSearch(false);
    } else setIsSearch(true);
    setSearch(
      coins.filter(
        (coin) =>
          coin?.name
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()) ||
          coin?.symbol
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()),
      ),
    );
  };

  const u = JSON.parse(localStorage.getItem('user'));
  const [balance, setBalance] = React.useState(0);
  const { user } = useUser(u?._id);

  const sendTransaction = () => {
    if (max < timer.min || max > balance) return;
    const tradeInfo = {
      coin: coin.id,
      amount: max,
      type: activeButton,
      duration: timer?.time,
    };
    if (activeButton === 'buy') {
      trade(tradeInfo);
    } else {
      trade(tradeInfo);
    }
  };

  React.useEffect(() => {
    if (user) {
      setBalance(user?.totalBalance);
    }
  }, [user]);

  return (
    <div className="trading_container_mobile">
      {/* Coin picker */}
      <div className="limit_buttons">
        {coinSelector && (
          <div className="coin_selector">
            <div className="selector_header">
              <h2 className="heading">Pick a coin</h2>
              <X onClick={() => setCoinSelector((prev) => !prev)} />
            </div>
            <input
              type="text"
              className="search_input"
              placeholder="Search for tokens"
              onChange={handleSearch}
            />
            {isSearch
              ? search?.map((coin) => (
                  <button
                    key={coin?.id}
                    onClick={() => {
                      setCoinSelector((prev) => !prev);
                      setCoin(coin);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                    className="limit_button"
                  >
                    <img src={coin?.icon} width={20} height={20} />
                    {`${coin?.symbol} - $${coin?.price?.toFixed(2)}`}
                  </button>
                ))
              : coins?.map((coin) => (
                  <button
                    key={coin?.id}
                    onClick={() => {
                      setCoinSelector((prev) => !prev);
                      setCoin(coin);
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                    }}
                    className="limit_button"
                  >
                    <img src={coin?.icon} width={20} height={20} />
                    {`${coin?.symbol} - $${coin?.price?.toFixed(2)}`}
                  </button>
                ))}
          </div>
        )}
        <button
          onClick={() => setCoinSelector((prev) => !prev)}
          className="limit_button"
        >
          <img
            width={20}
            height={20}
            src={coin?.icon}
            alt=""
            style={{ marginRight: '.5rem' }}
          />
          {coin && selector}
        </button>
      </div>
      {/* Coin Chart */}
      <div className="coin_chart">
        <div className="heading">
          <p className="heading_title">{coin?.name} Chart</p>
          <TimelineSelector
            setChangeTimeline={setChangeTimeline}
            changeTimeline={changeTimeline}
          />
        </div>
        {coin && (
          <TopCoinCard
            title={coin?.name}
            icon={coin?.icon}
            shortcut={coin?.symbol}
            price={coin?.price}
            change={coin?.priceChange1d}
            timeline={changeTimeline}
            id={coin?.id}
            secondary={true}
            color="#2dc24e"
          />
        )}
      </div>

      <div className="trading_container">
        <div className="timer_container">
          <p>Timer</p>
          <div className="time_selector">
            {times?.map((time) => {
              return (
                <div className="button_container">
                  <button
                    className={`timer_button ${
                      time.time == timer.time && 'timer_button_active'
                    }`}
                    key={time.time}
                    onClick={() => setTimer(time)}
                  >
                    {time.time}
                  </button>
                  <p className="rate">{time.rate}</p>
                </div>
              );
            })}
          </div>
        </div>

        <TradeCardBullet
          title="Trading amount"
          subtitle="0"
          setMax={setMax}
          min={timer?.min}
          placeholder={`Minimum $${timer?.min}`}
        />
        <p className="balance">
          Your balance ${balance ? balance?.toFixed(2) : '0.00'}
        </p>
        <div className="switch_container">
          <button
            onClick={() => setActiveButton('buy')}
            className={`switch_button ${
              activeButton === 'buy' ? 'active buy' : ''
            }`}
          >
            Buy
          </button>
          <button
            onClick={() => setActiveButton('sell')}
            className={`switch_button ${
              activeButton === 'buy' ? '' : 'active sell'
            }`}
          >
            Sell
          </button>
        </div>

        <div className="button_container">
          <button
            className={
              max >= timer.min && max <= balance ? 'active' : 'inactive'
            }
            onClick={sendTransaction}
          >
            Open Trade
          </button>
        </div>
      </div>
    </div>
  );
};

const Trading = () => {
  const { result, loading } = useTradeHistory();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const [coins, setCoins] = React.useState([]);
  const [changeTimeline, setChangeTimeline] = React.useState('24h');
  const [coin, setCoin] = React.useState({});
  React.useEffect(() => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=',
      },
    };
    setLoading(true);
    fetch('https://openapiv1.coinstats.app/coins?limit=500', options)
      .then((response) => response.json())
      .then((response) => setCoins(response.result))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    setCoin(coins[0]);
  }, [coins]);

  return (
    <div className="trading_wrapper">
      {loading && <LoaderOverlay />}
      {window.innerWidth <= 900 ? (
        <MobileTrading
          coins={coins}
          coin={coin}
          setCoin={setCoin}
          changeTimeline={changeTimeline}
          setChangeTimeline={setChangeTimeline}
        />
      ) : (
        <div className="trading_container">
          <TradeCard coins={coins} coin={coin} setCoin={setCoin} />
          <div className="coin_chart">
            <div className="heading">
              <p className="heading_title">{coin?.name} Chart</p>
              <TimelineSelector
                setChangeTimeline={setChangeTimeline}
                changeTimeline={changeTimeline}
              />
            </div>
            {coin && (
              <TopCoinCard
                title={coin?.name}
                icon={coin?.icon}
                shortcut={coin?.symbol}
                price={coin?.price}
                change={coin?.priceChange1d}
                timeline={changeTimeline}
                id={coin?.id}
                secondary={true}
                color="#2dc24e"
              />
            )}
          </div>
        </div>
      )}
      <TransactiosHistory history={result} size={'long'} />
    </div>
  );
};

export default Trading;
