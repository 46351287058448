import React from 'react';
import { capitalize } from '@utils/utils.astro';

const DashboardListItem = ({ title, icon, action, mainView }) => {
  return (
    <li
      onClick={() => action(title)}
      className={`dashboard_list_item ${mainView === title ? 'selected' : ''}`}
    >
      <span className="dashboard_list_item_icon">
        <img src={icon} alt={title} className="dashboard_list_item_icon_src" />
      </span>
      <h3 className="dashboard_list_item_title">{capitalize(title)}</h3>
    </li>
  );
};

export default DashboardListItem;
