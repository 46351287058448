import React from 'react';
import { Nav } from '@components';
import { useAuth } from '@hooks/useAuth';
import { LoaderContext } from '../../App';
import { LoaderOverlay } from '@components';

const Email = () => {
  document.title = 'Webtrade - Verify Email';
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState(null);
  const { user, verify, sendVerification, loading } = useAuth();
  const { setLoading, loading: load } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  //   if (user && user?.verified) {
  //     return window.location.replace("/dashboard/?view=dashboard");
  //   }

  const sendCode = async (e) => {
    e.preventDefault();
    if (!code || !user?._id) return;
    await verify(user?._id, code);
    window.location.replace('/dashboard/?view=dashboard');
  };

  return (
    <>
      {loading && <LoaderOverlay />}

      <section className="auth_page">
        <Nav logout={true} />
        <main className="auth_container">
          <div className="auth_info">
            <h1 className="auth_title">Verify your email</h1>
            <p className="auth_description">
              Welcome <span>{user?.name}</span>, we have sent a verification
              code to <span>{user?.email}</span>!
            </p>
          </div>
          <form className="auth_form">
            <div className="auth_form_group">
              <label htmlFor="email">Confirmation code</label>
              <input
                onFocus={() => setError(null)}
                type="text"
                id="code"
                name="code"
                onChange={(e) => setCode(e.target.value)}
                value={code}
                placeholder="Enter the confirmation code"
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button onClick={sendCode}>Verify</button>
            <p className="auth_description">
              Didn't recieve a verification mail?{' '}
              <span
                className="clickable"
                onClick={() => sendVerification(user?._id)}
              >
                resend
              </span>
            </p>
          </form>
        </main>
      </section>
    </>
  );
};

export default Email;
