import React from 'react';
import { Menu, X } from 'react-feather';
import { motion } from 'framer-motion';

const Nav = ({ hide, logout, scroll }) => {
  const variants =
    window.innerWidth <= 900
      ? {
          open: { opacity: 1, x: 0 },
          closed: { opacity: 0, x: '-100vw' },
        }
      : {
          open: { opacity: 1, x: 0 },
          closed: { opacity: 1, x: 0 },
        };
  const signout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const [open, setOpen] = React.useState(false);

  const triger = (ref) => {
    setOpen(false);
    if (window.location.pathname === '/') {
      scroll(ref);
    } else {
      window.location.href = '/';
    }
  };
  return (
    <>
      {window.innerWidth <= 900 && (
        <div className="tab">
          <a href="/">
            <img src="/logo.svg" alt="logo" className="logo" />
          </a>
          <Menu className="menu" color="#fff" onClick={() => setOpen(true)} />
        </div>
      )}
      <motion.nav
        className="header_navigation bg-[#000000] md:bg-[#00000044] backdrop-blur-[.3rem] fixed top-0 left-0 flex flex-col items-center justify-center gap-10 z-[100]"
        variants={variants}
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        transition={{ duration: 0.8, type: 'spring', ease: 'easeInOut' }}
      >
        <div
          className={`logo_container${window.innerWidth <= 900 && '_mobile'}`}
        >
          <a href="/">
            <img src="/logo.svg" alt="logo" className="logo" />
          </a>
          {window.innerWidth <= 900 && (
            <X className="close" color="#fff" onClick={() => setOpen(false)} />
          )}
        </div>
        <ul className="nav_links">
          <li className="nav_link">
            <a href="/dashboard/?view=dashboard">Dashboard</a>
          </li>
          <li className="nav_link" onClick={() => triger('about')}>
            About
          </li>
          <li className="nav_link" onClick={() => triger('faq')}>
            FAQ
          </li>
          <li className="nav_link" onClick={() => triger('contact')}>
            Contact
          </li>
        </ul>
        <div className="button_group">
          {logout ? (
            <button className="button" onClick={signout}>
              Sign out
            </button>
          ) : hide !== 'signin' && hide !== 'signup' ? (
            <>
              <a className="button" href="/auth/signin">
                Sign in
              </a>
              <a className="button" href="/auth/signup">
                Sign up
              </a>
            </>
          ) : hide !== 'signin' ? (
            <a className="button" href="/auth/signin">
              Sign in
            </a>
          ) : (
            <a className="button" href="/auth/signup">
              Sign up
            </a>
          )}
        </div>
      </motion.nav>
    </>
  );
};

export default Nav;
