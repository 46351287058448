import React from 'react';
import { formatPrice } from '@utils/utils.astro';
import useWallet from '@hooks/useWallet';
import useUser from '@hooks/useUser';
import { useTradeHistory } from '@hooks/useTrade';
import { LoaderContext } from '../../../App';
import { LoaderOverlay } from '@components';

const PortfolioHeader = () => {
  return (
    <div className="portfolio_header">
      <p className="coin_header_text">Assets</p>
      <p className="coin_header_text">Price</p>
      <p className="coin_header_text">Balance</p>
      <p className="coin_header_text">price change 24H</p>
      <p className="coin_header_text">Market Cap</p>
      <p className="coin_header_text">Profit/Loss</p>
    </div>
  );
};

const PortfolioRow = ({ coin }) => {
  const { icon, name, symbol, price, priceChange1d, marketCap } =
    coin?.coinData;
  const { balance } = coin;
  const profit = coin?.amount;
  const holding = balance * price;
  const percentage = (profit / holding) * 100;
  const percentageText = (percentage > 0 ? '+' : '') + percentage + '%';
  const profitText = (profit > 0 ? '+' : '') + formatPrice(profit) + '$';

  console.log(percentageText);
  return (
    <div className="portfolio_row">
      <div className="coin_info">
        <img src={icon} alt="coin icon" className="icon" />
        <p className="coin_name">{name}</p>
        <p className="coin_symbol">{symbol}</p>
      </div>
      <div className="coin_price">
        <p className="coin_price_text">${formatPrice(price.toFixed(2))}</p>
      </div>
      <div className="coin_balance">
        <p className="coin_balance_text">
          {(balance / price)?.toFixed(5)} {symbol}
        </p>
      </div>
      <div className="coin_price_change_24H">
        <p className="coin_price_change_24H_text">{priceChange1d}</p>
      </div>
      <div className="coin_market_cap">
        <p className="coin_market_cap_text">
          {formatPrice(marketCap.toFixed(2))}
        </p>
      </div>
      <div className="coin_profit_loss">
        <p className="coin_profit_loss_text">-</p>
      </div>
    </div>
  );
};

const MobilePortfolio = ({ transactions, total }) => {
  return transactions.length === 0 ? (
    <div className="empty_state">
      <h1 className="empty_state_title">
        It looks like you don't have any holdings yet
      </h1>
      <button
        className="empty_state_cta"
        onClick={() => {
          window.location.href = '/dashboard/?view=trading';
        }}
      >
        Trade now
      </button>
    </div>
  ) : (
    <div className="mobile_portfolio_container">
      <div className="wallet_info">
        <p className="subtitle">Your balance</p>
        <h1 className="title">${formatPrice(total)}</h1>
      </div>
      <div className="portfolio_table">
        <div className="mobile_portfolio_header">
          <p className="coin_header_text">Assets</p>
          <p className="coin_header_text">Balance</p>
        </div>
        <>
          {transactions?.map(({ coinData, balance }) => {
            return (
              <div className="mobile_portfolio_row" key={coinData?.symbol}>
                <div className="coin_info">
                  <img src={coinData?.icon} alt="coin icon" className="icon" />
                  <p className="coin_name">{coinData?.name}</p>
                  <p className="coin_symbol">{coinData?.symbol}</p>
                </div>
                <div className="coin_balance">
                  <p className="coin_balance_text">
                    {(balance / coinData?.price)?.toFixed(5)} {coinData?.symbol}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

// TODO: Link to wallet
const Portfolio = () => {
  const u = JSON.parse(localStorage.getItem('user'));

  if (!u) window.location.href = '/login';

  const { user } = useUser(u?._id);
  const userBalance = user?.totalBalance;
  const { result, loading } = useTradeHistory();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const [transactions, setTransactions] = React.useState([]);
  const [balance, setBalance] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [showList, setShowList] = React.useState(true);

  React.useEffect(() => {
    const uniqueItems = {};

    // Iterate over the array
    result?.forEach((item) => {
      const { coinId, limitPrice, loss, profit } = item;
      const balance = limitPrice - loss + profit;

      // Check if coinId already exists in uniqueItems
      if (uniqueItems.hasOwnProperty(coinId)) {
        // Update balance if coinId already exists
        uniqueItems[coinId].balance += balance;
      } else {
        // Create a new entry if coinId doesn't exist
        uniqueItems[coinId] = {
          coinId,
          balance,
        };
      }
    });

    // Convert the values of uniqueItems object to an array
    let resultArray = Object.values(uniqueItems);
    let userbalance = user?.totalBalance;
    resultArray.forEach((item) => {
      userbalance += item.balance;
    });
    setBalance(userbalance < 0 ? 0 : userbalance);

    // remove negative and null balances
    resultArray = resultArray.filter((item) => item?.balance > 0);

    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=',
      },
    };
    const getCoins = async () => {
      try {
        const coinDataPromises = resultArray?.map(async (item) => {
          const response = await fetch(
            `https://openapiv1.coinstats.app/coins/${item?.coinId}`,
            options,
          );
          const data = await response.json();
          return { ...item, coinData: data };
        });

        const updatedTransactions = await Promise.all(coinDataPromises);
        setTransactions(updatedTransactions);
      } catch (error) {
        console.error(error);
      }
    };
    getCoins();
  }, [result]);

  React.useEffect(() => {
    let t = 0;
    transactions?.forEach((item) => {
      t += item.balance;
    });
    setTotal(t);
    if (transactions.length === 0) setShowList(false);
    else setShowList(true);
    console.log(transactions);
  }, [transactions]);

  return (
    <div className="portfolio_container">
      {loading && <LoaderOverlay />}
      {window.innerWidth <= 900 ? (
        <MobilePortfolio total={userBalance} transactions={transactions} />
      ) : (
        <>
          <div className="header">
            <div className="wallet_info">
              <p className="subtitle">Your balance</p>
              <h1 className="title">${formatPrice(userBalance)}</h1>
            </div>
            <div className="heading_cta_container">
              <button
                className="cta"
                onClick={() => {
                  window.location.href = '/dashboard/?view=wallet';
                }}
              >
                Deposit
              </button>
              {balance > 0 && (
                <button
                  className="cta"
                  onClick={() => {
                    window.location.href = '/dashboard/?view=wallet';
                  }}
                >
                  Withdraw
                </button>
              )}
            </div>
          </div>

          <div className="holdings">
            <h1 className="title">Your holdings</h1>
            <div className="container">
              {transactions && transactions?.length > 0 ? (
                <div className="holdings_table_contaienr">
                  <PortfolioHeader />
                  <div className="table_rows">
                    {transactions?.map((wallet) => {
                      return <PortfolioRow coin={wallet} />;
                    })}
                  </div>
                </div>
              ) : (
                <div className="empty_state">
                  <h1 className="empty_state_title">
                    It looks like you don't have any holdings yet
                  </h1>
                  <button
                    className="empty_state_cta"
                    onClick={() => {
                      window.location.href = '/dashboard/?view=trading';
                    }}
                  >
                    Trade now
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Portfolio;
