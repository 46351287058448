import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  Home,
  Dashboard,
  Portfolio,
  Signin,
  Signup,
  Password,
  Email,
  Reset,
} from '@pages';
import 'react-loading-skeleton/dist/skeleton.css';
import { LoaderOverlay } from '@components';
import { Network } from './components';
import 'rsuite/dist/rsuite-no-reset.min.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/portfolio',
    element: <Portfolio />,
  },
  {
    path: '/auth/signin',
    element: <Signin />,
  },
  {
    path: '/auth/signup',
    element: <Signup />,
  },
  {
    path: '/auth/password',
    element: <Password />,
  },
  {
    path: '/auth/reset',
    element: <Reset />,
  },
  {
    path: '/auth/verify',
    element: <Email />,
  },
]);

export const LoaderContext = React.createContext();

const App = () => {
  const [loading, setLoading] = React.useState(false);
  const [offline, setOffline] = React.useState(false);

  React.useEffect(() => {
    const handleOffline = () => setOffline(true);
    const handleOnline = () => setOffline(false);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {offline && <Network />}
      {!offline && <RouterProvider router={router} />}
    </LoaderContext.Provider>
  );
};

export default App;
