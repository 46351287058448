import React from 'react';
import { DashboardListItem } from './';
import { useAuth } from '@hooks/useAuth';
import { X } from 'react-feather';
import { motion } from 'framer-motion';

const variants = {
  hidden: { left: '-100vw', opacity: 0 },
  visible: { left: 0, opacity: 1 },
};

const SideBar = ({
  dashboardList,
  mainView,
  setSearchParams,
  drawer,
  close,
}) => {
  const action = (item) => {
    setSearchParams({ view: item });
    close();
  };

  const { signout } = useAuth();
  const logout = () => {
    signout();
  };
  return (
    <motion.aside
      className="dashboard_sidebar_container"
      variants={variants}
      animate={drawer ? 'visible' : 'hidden'}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      initial="hidden"
    >
      <div className="top">
        <div className="logo_section">
          <a href="/">
            <img src="/logo.svg" alt="logo" className="logo" />
          </a>
          {window.innerWidth <= 900 && (
            <X size={26} color="white" onClick={close} />
          )}
        </div>
        <ul className="dashboard_list">
          {dashboardList?.map(({ title, icon }) => {
            return (
              <DashboardListItem
                key={title}
                title={title}
                icon={icon}
                action={action}
                mainView={mainView}
              />
            );
          })}
        </ul>
      </div>
      <div className="bottom">
        <DashboardListItem
          title={'Logout'}
          icon={'/dashboard/logout.svg'}
          action={logout}
        />
      </div>
    </motion.aside>
  );
};

export default SideBar;
