import React from 'react';
import useTrade from '@hooks/useTrade';
import useUser from '../../../hooks/useUser';

const TradeCardBullet = ({
  title,
  subtitle,
  value,
  setMax,
  currency = '',
  style,
  min = 0,
}) => {
  const inputRef = React.useRef(null);
  return (
    <div className="quick_trade_card" style={{ ...style }}>
      <p className="title">{title}</p>
      {setMax ? (
        <div className="input_container">
          <input
            ref={inputRef}
            type="number"
            name="num"
            id="num_input"
            placeholder="Enter limit price"
            min={min}
            onChange={(e) => setMax(e.target.value)}
          />
          <p>$</p>
        </div>
      ) : (
        <p className="subtitle" style={{ color: '#fff' }}>
          {value !== null ? currency + value : subtitle}
        </p>
      )}
    </div>
  );
};

const TradeCard = ({ coins, coin, setCoin }) => {
  const {
    activeButton,
    setActiveButton,
    coinSelector,
    setCoinSelector,
    timer,
    setTimer,
    max,
    setMax,
    selector,
    times,
    trade,
  } = useTrade(coins, coin, setCoin);

  const u = JSON.parse(localStorage.getItem('user'));
  const [balance, setBalance] = React.useState(0);
  const { user } = useUser(u?._id);

  React.useEffect(() => {
    if (user) {
      setBalance(user?.totalBalance);
    }
  }, [user]);

  const sendTransaction = () => {
    if (max < timer.min || max > balance) return;
    const tradeInfo = {
      coin: coin.id,
      amount: max,
      type: activeButton,
      duration: timer.time,
    };
    if (activeButton === 'buy') {
      trade(tradeInfo);
    } else {
      trade(tradeInfo);
    }
  };

  return (
    <div className="container trade_container">
      <div className="header">
        <h3 className="title">Trade</h3>
        <div className="switch_container">
          <button
            onClick={() => setActiveButton('buy')}
            className={`switch_button ${
              activeButton === 'buy' ? 'active' : ''
            }`}
          >
            Buy
          </button>
          <button
            onClick={() => setActiveButton('sell')}
            className={`switch_button ${
              activeButton === 'buy' ? '' : 'active'
            }`}
          >
            Sell
          </button>
        </div>
      </div>
      <div className="limit_buttons" style={{ position: 'relative' }}>
        {coinSelector && (
          <div
            className="coin_selector"
            style={{
              backgroundColor: '#000',
              gap: '1rem',
              padding: '1rem',
              paddingTop: '1rem',
              borderRadius: '1rem',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '20rem',
              overflowY: 'scroll',
              overflowX: 'hidden',
              position: 'absolute',
              top: '10',
              left: '0',
            }}
          >
            {coins?.map((coin) => (
              <button
                key={coin?.id}
                onClick={() => {
                  setCoinSelector((prev) => !prev);
                  setCoin(coin);
                }}
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                className="limit_button"
              >
                <img src={coin?.icon} width={20} height={20} />
                {`${coin?.symbol} - $${coin?.price?.toFixed(2)}`}
              </button>
            ))}
          </div>
        )}
        <button
          onClick={() => setCoinSelector((prev) => !prev)}
          className="limit_button"
        >
          {coin && selector}
        </button>
      </div>
      <div className="quick_trade_container">
        <div>
          <div className="timer_container">
            <p>Timer</p>
            <div className="time_selector">
              {times?.map((time) => {
                return (
                  <button
                    className={`timer_button ${
                      time.time == timer.time && 'timer_button_active'
                    }`}
                    key={time.time}
                    onClick={() => setTimer(time)}
                  >
                    {time.time}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <TradeCardBullet
          title="Your balance"
          subtitle="$0"
          value={
            balance != undefined && balance != null
              ? balance?.toFixed(2)
              : '0.00'
          }
          currency="$"
        />
        <TradeCardBullet
          title="minimum trading amount"
          subtitle="$129634.8374"
          value={timer?.min}
          currency="$"
        />
        <TradeCardBullet
          title="Profit rate"
          subtitle="$129634.8374"
          value={timer?.rate}
          currency=""
        />
        <hr color="#212325" />
      </div>

      <TradeCardBullet
        title={activeButton === 'buy' ? 'Maximum amount' : 'Minimum amount'}
        subtitle="0"
        setMax={setMax}
        min={timer?.min}
        style={{ marginBottom: '2rem' }}
      />
      <div className="button_container">
        <button
          className={max >= timer.min && max <= balance ? 'active' : 'inactive'}
          onClick={sendTransaction}
        >
          Open Trade
        </button>
      </div>
    </div>
  );
};

export default TradeCard;
