import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false, // Hide legend
    },
    title: {
      display: false, // Hide title
    },
  },
  scales: {
    x: {
      display: false, // Hide x-axis
    },
    y: {
      display: false, // Hide y-axis
    },
  },
};

const TopCoinCard = ({
  title,
  icon,
  shortcut,
  price,
  change,
  timeline,
  id,
  secondary,
  color,
}) => {
  const [chart, setChart] = React.useState(null);
  React.useEffect(() => {
    // TODO: MOVE API KEY TO .ENV
    if (id) {
      const options = {
        method: 'GET',
        headers: {
          'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=',
        },
      };

      fetch(
        `https://openapiv1.coinstats.app/coins/${id}/charts?period=${timeline}`,
        options,
      )
        .then((response) => response.json())
        .then((response) => {
          // response?.map((data) => setChangeHistory(data[1]));
          setChart({
            labels: response?.map((data, index) => index + 1),
            datasets: [
              {
                fill: true,
                label: `${title} ($)`,
                data: response?.map((data) => data[1]),
                borderColor: color
                  ? color
                  : secondary
                    ? 'rgba(255, 255, 255, .12)'
                    : '#77ED91',
                backgroundColor: (context) => {
                  const gradient = context.chart.ctx.createLinearGradient(
                    0,
                    0,
                    0,
                    context.chart.height,
                  );
                  gradient.addColorStop(
                    0,
                    secondary ? 'rgba(255, 255, 255, .1)' : '#77ED91',
                  ); // start color
                  gradient.addColorStop(
                    1,
                    secondary
                      ? 'rgba(255, 255, 255, .01)'
                      : 'rgba(255, 255, 255, .1)',
                  ); // end color
                  return gradient;
                },
                pointRadius: 0,
                borderWidth: 1,
              },
            ],
          });
        })
        .catch((err) => console.error(err));
    }
  }, [timeline, id]);

  const [showInfo, setShowInfo] = React.useState(true);

  if (!id) return <></>;
  return (
    <div
      onMouseEnter={() => setShowInfo(false)}
      onMouseLeave={() => setShowInfo(true)}
      className={`top_coin_card ${secondary ? 'top_coin_card_secondary' : ''}`}
    >
      <div className="info" style={showInfo ? {} : { height: 'unset' }}>
        <div className="top">
          <div className="heading">
            <img src={icon} alt={title} className="coin_icon" />
            <img
              src={
                secondary
                  ? '/dashboard/expand_light.svg'
                  : '/dashboard/expand.svg'
              }
              alt="expand icon"
              className="expand_icon"
            />
          </div>
          {showInfo && (
            <div className="content">
              <p className="coin_name">{title}</p>
              <h3 className="coin_price">${price?.toFixed(2)}</h3>
            </div>
          )}
        </div>
        {showInfo && (
          <p
            className={`change_purcentage ${
              secondary ? (change > 0 ? 'green' : 'red') : ''
            }`}
          >
            {change > 0 ? '+' + change : '' + change}% <span>24h</span>
          </p>
        )}
      </div>
      <div className="chart">
        {chart ? (
          <Line options={options} data={chart} style={{ cursor: 'pointer' }} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TopCoinCard;
