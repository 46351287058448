/*
binance.svg  metamask.svg tether.svg
eos.svg      opensea.svg  tron.svg
ethereum.svg solana.svg   xrp.svg
*/

import binance from '../assets/logos/binance.svg';
import eos from '../assets/logos/eos.svg';
import ethereum from '../assets/logos/ethereum.svg';
import metamask from '../assets/logos/metamask.svg';
import opensea from '../assets/logos/opensea.svg';
import solana from '../assets/logos/solana.svg';
import tether from '../assets/logos/tether.svg';
import tron from '../assets/logos/tron.svg';
import xrp from '../assets/logos/xrp.svg';

const images = [
  {
    id: 0,
    image: binance,
  },
  {
    id: 1,
    image: eos,
  },
  {
    id: 2,
    image: ethereum,
  },
  {
    id: 3,
    image: metamask,
  },
  {
    id: 4,
    image: opensea,
  },
  {
    id: 5,
    image: solana,
  },
  {
    id: 6,
    image: tether,
  },
  {
    id: 7,
    image: tron,
  },
  {
    id: 8,
    image: xrp,
  },
];

export default images;
