import React from 'react';
import {
  PriceChangeItem,
  TopCoinCard,
  SkeletonTopCoinCard,
  SkeletonCard,
  TimelineSelector,
  QuickTradeCard,
  TradeCard,
  TransactiosHistory,
} from '@dashboard_components';
import { LoaderContext } from '../../../App';
import { MobileTransactionList } from '@components';
import { useTradeHistory } from '@hooks/useTrade';
import { MobileTrading } from './Trading';

const TradeHistory = ({ coins }) => {
  const [coin, setCoin] = React.useState(coins[0]);
  const { result, loading } = useTradeHistory();
  const { setLoading } = React.useContext(LoaderContext);
  const [changeTimeline, setChangeTimeline] = React.useState('24h');
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  return (
    <section className="trade_history">
      {window.innerWidth <= 900 ? (
        <MobileTrading
          coins={coins}
          coin={coin}
          setCoin={setCoin}
          changeTimeline={changeTimeline}
          setChangeTimeline={setChangeTimeline}
        />
      ) : (
        <TradeCard coins={coins} coin={coin} setCoin={setCoin} />
      )}
      {window.innerWidth > 900 ? (
        <TransactiosHistory history={result} size={'short'} />
      ) : (
        <MobileTransactionList history={result} />
      )}
    </section>
  );
};

const Dash = () => {
  const [data, setData] = React.useState([]);
  const [recent, setReacent] = React.useState([]);
  const [coins, setCoins] = React.useState([]);
  const [change, setChange] = React.useState([]);
  const [changeTimeline, setChangeTimeline] = React.useState('24h');
  React.useEffect(() => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=',
      },
    };
    const optionsx = {
      headers: {
        'x-access-token':
          'coinranking4ded10f747df5b6b1d7bd6308b7f74db00556fc03bacc935',
      },
    };

    fetch('https://api.coinranking.com/v2/coins?limit=3&tags=dao', optionsx)
      .then((response) => response.json())
      .then((result) => {
        setReacent(result.data.coins);
      });

    fetch('https://openapiv1.coinstats.app/coins?limit=3', options)
      .then((response) => response.json())
      .then((response) => setData(response.result))
      .catch((err) => console.error(err));
    fetch('https://openapiv1.coinstats.app/coins?limit=50', options)
      .then((response) => response.json())
      .then((response) => setCoins(response.result))
      .catch((err) => console.error(err));
    fetch('https://openapiv1.coinstats.app/coins?limit=20', options)
      .then((response) => response.json())
      .then((response) => setChange(response.result))
      .catch((err) => console.error(err));
  }, []);
  return (
    <article className="dashboard_section">
      {/* PRICE CHANGE HISTORY */}
      <section className="price_change_container">
        <p className="price_change_title">Today's prices by webtrade</p>
        <section className="price_change_lists">
          <section className="price_change_list">
            <div className="heading">
              <p className="heading_title">Trending</p>
              {/* <p className="heading_cta">See all</p> */}
            </div>
            <div>
              {data.length > 0 ? (
                data?.map(({ rank, icon, name, symbol, priceChange1d }) => {
                  return (
                    <PriceChangeItem
                      key={rank}
                      rank={rank}
                      icon={icon}
                      title={name}
                      shortcut={symbol}
                      change={priceChange1d}
                      currency="%"
                    />
                  );
                })
              ) : (
                <SkeletonTopCoinCard count={3} />
              )}
            </div>
          </section>
          <section className="price_change_list">
            <div className="heading">
              <p className="heading_title">Recently added</p>
              {/* <p className="heading_cta">See all</p> */}
            </div>
            <div>
              {data.length > 0 ? (
                recent?.map(
                  ({ rank, iconUrl, name, symbol, change }, index) => {
                    return (
                      <PriceChangeItem
                        key={rank}
                        rank={index + 1}
                        icon={iconUrl}
                        title={name}
                        shortcut={symbol}
                        change={change}
                        currency="$"
                      />
                    );
                  },
                )
              ) : (
                <SkeletonTopCoinCard count={3} />
              )}
            </div>
          </section>
        </section>
      </section>
      {/* TOP COINS */}
      <section className="top_coins_container">
        <div className="heading">
          <p className="heading_title">Top coins</p>
          <TimelineSelector
            setChangeTimeline={setChangeTimeline}
            changeTimeline={changeTimeline}
          />
        </div>
        <div className="coins_cards">
          <div className="cards_container">
            {change.length > 0 ? (
              change?.map(
                (
                  { rank, icon, name, symbol, price, priceChange1d, id },
                  index,
                ) => {
                  return (
                    <TopCoinCard
                      key={id}
                      rank={rank}
                      title={name}
                      icon={icon}
                      shortcut={symbol}
                      price={price}
                      change={priceChange1d}
                      timeline={changeTimeline}
                      id={id}
                      secondary={index >= 1}
                    />
                  );
                },
              )
            ) : (
              <SkeletonCard count={10} />
            )}
          </div>
        </div>
      </section>
      {/* TRADE AND HISTORY */}
      <TradeHistory coins={coins} />
    </article>
  );
};

export default Dash;
