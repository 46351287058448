import React, { useState, useEffect } from 'react';
import { Send, Send2, CloseCircle } from 'iconsax-react';
import axios from 'axios';

const useChat = ({logsRef}) => {
  const server = 'https://webtrade.onrender.com';
  const route = '/chat';

  const [chatLog, setChatLog] = useState([{ type: 'bot', message: "Hello, how can I help you?" }]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
	setError(null);
	setLoading(false);
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userMessage = input.trim();
    setChatLog(prev => [...prev, { type: 'user', message: userMessage }]);
	sendMessage(userMessage);
    setInput('');
  };

  const sendMessage = async (message) => {
    setLoading(true);
	setError(null);
    try {
      const response = await axios.post(server + route, { message });
      setChatLog(prev => [...prev, { type: 'bot', message: response.data.message }]);
	  setInput('');
	  setError(null);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  return {
    chatLog,
    input,
    loading,
    error,
    handleInputChange,
    handleSubmit,
    sendMessage,
  };
};

const ChatModal = ({closeChat}) => {
	const logsRef = React.useRef(null);
  const {
    chatLog,
    input,
    loading,
    error,
    handleInputChange,
    handleSubmit,
    sendMessage,
  } = useChat({logsRef});

  const scrollToBottom = () => {
    if (logsRef.current) {
      logsRef.current.scrollTop = logsRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  return (
    <div className="p-4 bg-white rounded-2xl w-[30vw] max-h-[80vh] flex flex-col justify-end">
		<div className="w-full flex items-center justify-between pb-6 pt-2">
			<h1 className='text-black'>Webtrade Assisstant</h1>
			<CloseCircle size={24} color="#000" onClick={closeChat} className='cursor-pointer' />
		</div>
      <div className="flex-1 flex flex-col overflow-scroll" ref={logsRef}>
        {chatLog.map((entry, index) => (
          <div key={index} className={`p-4 rounded-2xl ${entry.type === "bot" ? "bg-[#b1b1b1] self-start" : "bg-[#282828] self-end"} w-fit mb-4`}>
            <p>
			{entry.message}
			</p>
          </div>
        ))}
		{
			loading && <div className="p-4 rounded-2xl bg-[#b1b1b1] self-start w-fit mb-4"><p>Loading...</p></div>
		}
		{
			error && <div className="p-4 rounded-2xl bg-[#b1b1b1] self-start w-fit mb-4"><p>An error occurred</p></div>
		}
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-center gap-3 border-t-2 pt-4"
      >
        <input
          type="text"
          className="border-2 border-gray-300 focus:border-black rounded-full p-2 text-md text-black placeholder:text-gray-300 px-4 py-4 active:outline-none focus:outine-none transition-all duration-300 ease-in-out"
          value={input}
          onChange={handleInputChange}
          placeholder="Ask a question..."
        />
        <Send2
          className="cursor-pointer"
          size={24}
          color="#000"
          onClick={() => {
            sendMessage(input);
          }}
        />
      </form>
    </div>
  );
};

export default ChatModal;
