import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonTopCoinCard = ({ count }) => {
  const skeletonMap = (() => {
    let arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(i);
    }
    return arr;
  })();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {skeletonMap?.map((item) => (
        <Skeleton
          key={item}
          style={{ marginBottom: 0 }}
          borderRadius={60}
          highlightColor="#151617"
          baseColor="#212325"
          enableAnimation={true}
          className="price_change_item"
        />
      ))}
    </div>
  );
};

export default SkeletonTopCoinCard;
