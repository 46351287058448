import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Offline, Online } from 'react-detect-offline';
import { AuthProvider } from './hooks/useAuth';
import { Network } from './components';
import { LoaderOverlay } from '@components';
// TODO: Design the offline page
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <React.StrictMode>
      {/* <Online> */}
      <App />
      {/* </Online> */}
      {/* <Offline> */}
      {/* <Network /> */}
      {/* </Offline> */}
    </React.StrictMode>
  </AuthProvider>,
);
