import React from 'react';

// mini game to play while the user is offline

const Network = () => {
  return (
    <main
      className="network_issue"
      style={network_fallback_styles.network_issue}
    >
      <h1 className="heading" style={network_fallback_styles.heading}>
        Ooops
      </h1>
      <p className="text" style={network_fallback_styles.text}>
        It seems like you're offline, please check your network connection and
        try again.
      </p>
    </main>
  );
};

const network_fallback_styles = {
  network_issue: {
    width: '100vw',
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
  },
  button: {
    padding: '10px',
    margin: '10px',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  text: {
    fontSize: window.innerWidth > 900 ? '1.5rem' : '1rem',
    textAlign: 'center',
  },
  heading: {
    fontSize: window.innerWidth > 900 ? '2rem' : '1.5rem',
  },
};

export default Network;
