import React, { useEffect } from 'react';
import { SideBar, Main } from './components';
import { useSearchParams } from 'react-router-dom';
import { capitalize } from '@utils/utils.astro';
import { useAuth } from '@hooks/useAuth';
import { LoaderContext } from '../../App';
import useTrading from '@hooks/useTrading';

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mainView, setMainView] = React.useState('Dashboard');
  const [auth, setAuth] = React.useState(false);
  const check = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    // if (user && !user?.verified) window.location.replace("/auth/verify");

    if (!token) {
      window.location.replace('/auth/signin');
      setAuth(false);
    } else setAuth(true);
  };

  React.useEffect(() => {
    check();
  }, []);

  const dashboardList = [
    { title: 'dashboard', icon: '/dashboard/dashboard.svg' },
    { title: 'trading', icon: '/dashboard/trading.svg' },
    { title: 'wallet', icon: '/dashboard/wallet.svg' },
    { title: 'portfolio', icon: '/dashboard/portfolio.svg' },
    { title: 'watchlist', icon: '/dashboard/watchlist.svg' },
    // { title: "profile", icon: "/dashboard/profile.svg" },
  ];

  const { user, loading } = useAuth();
  const { setLoading } = React.useContext(LoaderContext);
  const { trades } = useTrading(user?._id);

  React.useEffect(() => {
    setLoading(false);
  }, [loading]);
  setLoading(false);

  const [drawer, setDrawer] = React.useState(
    window.innerWidth <= 900 ? false : true,
  );

  const close = () => {
    window.innerWidth <= 900 && setDrawer(false);
  };

  useEffect(() => {
    const view = searchParams.get('view');
    if (view) {
      setMainView(view);
    }
    document.title = `Webtrade - ${view ? capitalize(view) : 'Dashboard'}`;
  }, [searchParams]);

  return auth ? (
    <section className="dashboard_screen_container">
      <SideBar
        dashboardList={dashboardList}
        setMainView={setMainView}
        setSearchParams={setSearchParams}
        mainView={mainView}
        drawer={drawer}
        close={close}
      />
      <Main
        mainView={mainView}
        user={user}
        drawer={drawer}
        setDrawer={setDrawer}
      />
    </section>
  ) : (
    <></>
  );
};

export default Dashboard;
