import React from 'react';

const TimelineSelector = ({ setChangeTimeline, changeTimeline }) => {
  return (
    <select
      name="history"
      id="drop_down"
      value={changeTimeline}
      className="drop_down"
      onChange={(e) => {
        setChangeTimeline(e.target.value);
      }}
    >
      <option value="all">All time</option>
      <option value="24h">1 day</option>
      <option value="1w">1 week</option>
      <option value="1m">1 month</option>
      <option value="3m">3 months</option>
      <option value="6m">6 months</option>
      <option value="1y">1 year</option>
    </select>
  );
};

export default TimelineSelector;
