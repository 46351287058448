import React from 'react';
import { NavBar } from './';
import {
  Dash,
  Portfolio,
  Trading,
  Watchlist,
  Profile,
  Wallet,
} from '../containers';

const Main = ({ mainView, user, drawer, setDrawer }) => {
  const getView = () => {
    switch (mainView) {
      case 'dashboard':
        return <Dash user={user} />;
      case 'portfolio':
        return <Portfolio user={user} />;
      case 'trading':
        return <Trading user={user} />;
      case 'watchlist':
        return <Watchlist user={user} />;
      case 'profile':
        return <Profile user={user} />;
      case 'wallet':
        return <Wallet user={user} />;
      default:
        return <Dash user={user} />;
    }
  };

  return (
    <section className="dashboard_main_view_container">
      <NavBar mainView={mainView} user={user} open={() => setDrawer(true)} />
      <main className="main_dashboard_container">{getView()}</main>
    </section>
  );
};

export default Main;
