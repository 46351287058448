import React from 'react';

const PriceChangeItem = ({ rank, icon, title, shortcut, change, currency }) => {
  return (
    <div className="price_change_item">
      <div className="left">
        <p className="price_change_item_rank">{rank}</p>
        <img className="price_change_item_icon" src={icon} alt={shortcut} />
        <div className="title_container">
          <p className="price_change_item_title">{title}</p>
          <p className="price_change_item_subtitle">{shortcut}</p>
        </div>
      </div>
      <p
        className={`price_change_item_change ${
          change > 0 ? 'success' : change < 0 ? 'error' : ''
        }`}
      >
        {change > 0 ? '+' + change : '' + change}
        {currency}
      </p>
    </div>
  );
};

export default PriceChangeItem;
