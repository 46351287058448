import React from 'react';
import { Nav } from '@components';
import { useAuth } from '@hooks/useAuth';
import { Eye, EyeOff } from 'react-feather';
import { LoaderContext } from '../../App';
import { LoaderOverlay } from '@components';

const Password = () => {
  document.title = 'Webtrade - Reset Password';

  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const token = params.get('token');
  if (!id || !token) window.location.replace('/');

  const [error, setError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [passwordVisible1, setPasswordVisible1] = React.useState(false);
  const [passwordVisible2, setPasswordVisible2] = React.useState(false);
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const { user, reset, loading } = useAuth();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  if (user) {
    return window.location.replace('/dashboard/?view=dashboard');
  }

  const changePassword = async (e) => {
    e.preventDefault();
    if (password1.length < 8)
      return setPasswordError('Password must be at least 8 characters');
    else if (!/\d/.test(password1))
      return setPasswordError('Password must contain at least one number');
    else if (!/[a-z]/.test(password1))
      return setPasswordError(
        'Password must contain at least one lowercase letter',
      );
    else if (!/[A-Z]/.test(password1))
      return setPasswordError(
        'Password must contain at least one uppercase letter',
      );
    else if (!/[^A-Za-z0-9]/.test(password1))
      return setPasswordError('Password must contain at least one symbol');
    if (password1 !== password2) return setError('Passwords do not match');
    const data = await reset(id, token, password1);
    if (data?.error) return setError(data.error);
  };

  return (
    <>
      {loading && <LoaderOverlay />}
      <section className="auth_page">
        <Nav hide="signup" />
        <main className="auth_container">
          <div className="auth_info">
            <h1 className="auth_title">Change Password</h1>
          </div>
          <form className="auth_form">
            <div className="auth_form_group">
              <label htmlFor="password">New Password</label>
              <div className="password_input">
                <input
                  onFocus={() => {
                    setError(null);
                    setPasswordError(null);
                  }}
                  type={passwordVisible1 ? 'text' : 'password'}
                  autoCorrect="off"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoSave="off"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword1(e.target.value)}
                  value={password1}
                  placeholder="Enter your new password"
                />
                <div className="eye_container">
                  {passwordVisible1 ? (
                    <EyeOff
                      style={{ cursor: 'pointer' }}
                      color="rgba(119, 237, 145, .2)"
                      className="password_toggle"
                      onClick={() => setPasswordVisible1(false)}
                    />
                  ) : (
                    <Eye
                      style={{ cursor: 'pointer' }}
                      color="rgba(119, 237, 145, 1)"
                      className="password_toggle"
                      onClick={() => setPasswordVisible1(true)}
                    />
                  )}
                </div>
              </div>
              {passwordError && <p className="error">{passwordError}</p>}
            </div>
            <div className="auth_form_group">
              <label htmlFor="password">Confirm Password</label>
              <div className="password_input">
                <input
                  onFocus={() => setError(null)}
                  type={passwordVisible2 ? 'text' : 'password'}
                  autoCorrect="off"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoSave="off"
                  id="password"
                  name="password"
                  onChange={(e) => setPassword2(e.target.value)}
                  value={password2}
                  placeholder="Confirm your password"
                />
                <div className="eye_container">
                  {passwordVisible2 ? (
                    <EyeOff
                      style={{ cursor: 'pointer' }}
                      color="rgba(119, 237, 145, .2)"
                      className="password_toggle"
                      onClick={() => setPasswordVisible2(false)}
                    />
                  ) : (
                    <Eye
                      style={{ cursor: 'pointer' }}
                      color="rgba(119, 237, 145, 1)"
                      className="password_toggle"
                      onClick={() => setPasswordVisible2(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            {error && <p className="error">{error}</p>}
            <button onClick={changePassword}>Change Password</button>
          </form>
        </main>
      </section>
    </>
  );
};

export default Password;
