import React, { useEffect } from 'react';
import { TrendingUp, TrendingDown, X, Copy, Check } from 'react-feather';
import useDeposit from '@hooks/useDeposit';
import useWithdraw from '@hooks/useWithdraw';
import useWallet from '@hooks/useWallet';
import useUser from '@hooks/useUser';
import { useTradeHistory } from '@hooks/useTrade';
import { TransactiosHistory } from '../components';
import { LoaderContext } from '../../../App';
import { LoaderOverlay } from '@components';

const WalletModal = ({ closeModal }) => {
  const {
    address,
    copied,
    copy,
    amount,
    handleAmountChange,
    hash,
    handleHashChange,
    handleFileChange,
    deposit,
    proof,
    loading,
  } = useDeposit({ closeModal: closeModal });
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <section
      className="wallet_modal_wrapper"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading && <LoaderOverlay />}
      <div className="wallet_modal" onClick={(e) => e.stopPropagation()}>
        <div className="wallet_modal_header">
          <h1 className="wallet_modal_title">Deposit to your wallet</h1>
          <X className="wallet_modal_close" onClick={closeModal} />
        </div>
        <div className="wallet_modal_body">
          <p className="wallet_modal_description">
            To deposit funds to your wallet, send the desired USD amount to one
            of the addresses below.
          </p>
          <div
            className="addresses_container"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              //   overflowX: "scroll",
              gap: '1rem',
            }}
          >
            {address?.map((_a, index) => (
              <div
                className={`address_container ${copied[index] && 'copied'}`}
                onClick={() => copy(index)}
              >
                <img
                  src={
                    _a.network === 'BTC'
                      ? '/wallets/btc.svg'
                      : _a.network === 'USDT TRC20'
                        ? '/wallets/usdt-trc20.svg'
                        : _a.network === 'ETH'
                          ? '/wallets/eth.svg'
                          : '/wallets/usdt.svg'
                  }
                  alt="Blockchain logo"
                  width={20}
                  height={20}
                />
                <p className="address" onClick={() => copy(index)}>
                  {copied[index]
                    ? 'Copied to clipboard'
                    : _a?.hash?.length > 20 && window.innerWidth <= 900
                      ? _a?.hash?.slice(0, 20) + '...'
                      : _a?.hash}
                </p>
                {copied[index] ? (
                  <Check className="copied" />
                ) : (
                  <Copy className="copy" onClick={() => copy(index)} />
                )}
              </div>
            ))}
          </div>
          <p className="wallet_modal_description">
            To confirm your deposit, please attach a screenshot of the
            transaction.
          </p>
          <input
            type="file"
            name="proof"
            id="proof"
            className="file_input"
            onChange={handleFileChange}
          />
          <input
            type="number"
            name="amount"
            id="amount"
            className="text_input"
            placeholder="Enter the desired amount"
            onChange={handleAmountChange}
          />
          {/* <input
            type="text"
            name="hash"
            id="hash"
            className="text_input"
            placeholder="Enter transaction hash"
            value={hash}
            onChange={handleHashChange}
          /> */}
          <p className="wallet_modal_description">
            Please note that it may take up to 2 hours for your deposit to be
            reflected in your wallet.
          </p>
        </div>
        <button
          className={`modal_submit ${
            (amount === '' || !proof) && 'disabled_button'
          }`}
          onClick={deposit}
        >
          Confirm Deposit
        </button>
      </div>
    </section>
  );
};

const Withdraw = ({ closeModal, balance }) => {
  const {
    amount,
    address,
    handleAmountChange,
    handleAddressChange,
    withdraw,
    loading,
    error,
  } = useWithdraw({ closeModal: closeModal });
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  return (
    <section
      className="wallet_modal_wrapper"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading && <LoaderOverlay />}
      <div className="wallet_modal" onClick={(e) => e.stopPropagation()}>
        <div className="wallet_modal_header">
          <h1 className="wallet_modal_title">Withdraw from your wallet</h1>
          <X className="wallet_modal_close" onClick={closeModal} />
        </div>
        <div className="wallet_modal_body">
          <p className="wallet_modal_description">
            To withdraw funds from your wallet, enter the desired amount and the
            address to which you want to send the funds.
          </p>
          <h3>Your balance: ${balance ? balance : '0.00'}</h3>
          <input
            type="number"
            name="amount"
            id="amount"
            className={`text_input ${error && 'input_error'}`}
            placeholder="Enter the desired amount"
            onChange={(e) => handleAmountChange(e, balance)}
          />
          {error && <p className="error_message">{error}</p>}
          <input
            type="text"
            name="address"
            id="address"
            className="text_input"
            placeholder="Enter the wallet address"
            value={address}
            onChange={handleAddressChange}
          />
          <p className="wallet_modal_description">
            Please note that it may take up to 2 hours for your withdrawal to be
            reflected in your wallet.
          </p>
        </div>
        <button
          className={`modal_submit ${
            (amount === '' || address === '' || error) && 'disabled_button'
          }`}
          onClick={() => {
            if (amount === '' || address === '' || error) return;
            withdraw();
          }}
        >
          Confirm Withdrawal
        </button>
      </div>
    </section>
  );
};

const Wallet = () => {
  const { _id } = JSON.parse(localStorage.getItem('user'));
  const { user, updateUser, loading } = useUser(_id);
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const { wallets } = useWallet();
  useEffect(() => {
    console.log(wallets);
  }, [wallets]);
  const [modal, setModal] = React.useState(null);

  const openCloseModal = (mode = null) => {
    if (modal === mode) setModal(null);
    else setModal(mode);
  };

  const formatDate = (date) => {
    return `${date.split('T')[0]} ${date.split('T')[1].split('.')[0]}`;
  };

  return (
    <div className="wallet_container" style={modal && { height: '100dvh' }}>
      {loading && <LoaderOverlay />}
      {modal === 'deposit' && (
        <WalletModal closeModal={() => openCloseModal(null)} />
      )}
      {modal === 'withdraw' && (
        <Withdraw
          closeModal={() => openCloseModal(null)}
          balance={user?.totalBalance?.toFixed(2)}
        />
      )}

      <div className="header">
        <div className="header_meta">
          <p className="title">
            Total balance: ${user?.totalBalance?.toFixed(2)}
          </p>
          {/* <p className="title">Total holdings: ${total?.toFixed(2)}</p> */}
        </div>
        <div className="header_cta">
          <button
            className="header_button"
            onClick={() => openCloseModal('deposit')}
          >
            Deposit
          </button>
          <button
            className="header_button"
            onClick={() => openCloseModal('withdraw')}
          >
            Withdraw
          </button>
        </div>
      </div>
      <div className="wallet_history_container">
        <div className="wallet_history">
          <h1 className="title">Deposits</h1>
          <div className="list_container">
            <div className="list_header">
              <p className="list_header_title">Date</p>
              <p className="list_header_title">Amount</p>
            </div>
            {wallets?.deposits?.length === 0 && (
              <div className="empty_state">
                <p className="fallback_text">
                  You haven't made any deposits yet.
                </p>
              </div>
            )}
            <div className="body">
              {wallets?.deposits?.map((wallet) => {
                return (
                  <div className="history_item" key={wallet?._id}>
                    <p className="history_name">
                      {formatDate(wallet?.createdAt) || '-'}
                    </p>
                    <p className="history_amount">{wallet?.amount}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="wallet_history">
          <h1 className="title">Withdraws</h1>
          <div className="list_container">
            <div className="list_header">
              <p className="list_header_title">Date</p>
              <p className="list_header_title">Amount</p>
            </div>
            {wallets?.withdraws?.length === 0 && (
              <div className="empty_state">
                <p className="fallback_text">
                  You haven't made any withdraws yet.
                </p>
              </div>
            )}
            <div className="body">
              {wallets?.withdraws?.map((wallet) => {
                return (
                  <div key={wallet?._id} className="history">
                    <div className="history_item">
                      <p className="history_name">
                        {formatDate(wallet?.createdAt) || '-'}
                      </p>
                      <p className="history_amount">{wallet?.amount}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
