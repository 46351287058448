import React from 'react';
import { compareDates } from '@utils/utils.astro';
import { Timer, MobileTransactionList } from '@components';

const HistoryListHeaderShort = () => {
  return (
    <div className="history_list_header_short">
      <div className="item">
        <p className="history_list_header_long_item">Time left</p>
      </div>
      <div className="item">
        <p className="history_list_header_short_item">Token</p>
      </div>
      <div className="item">
        <p className="history_list_header_short_item">Max Trade Amount</p>
      </div>
      <div className="item">
        <p className="history_list_header_short_item">Result</p>
      </div>
    </div>
  );
};

const HistoryListHeaderLong = () => {
  return (
    <div className="history_list_header_long">
      <div className="item">
        <p className="history_list_header_long_item">Date</p>
      </div>
      <div className="item">
        <p className="history_list_header_long_item">Time left</p>
      </div>
      <div className="item">
        <p className="history_list_header_long_item">Token</p>
      </div>
      <div className="item">
        <p className="history_list_header_long_item">Type</p>
      </div>
      <div className="item">
        <p className="history_list_header_long_item">Max Trade Amount</p>
      </div>
      <div className="item">
        <p className="history_list_header_long_item">Result</p>
      </div>
    </div>
  );
};

const HistoryItemShort = ({ item }) => {
  const date = new Date();
  const end = new Date(item?.endDate);
  const [status, setStatus] = React.useState(
    date.getTime() < end.getTime() ? 'open' : item?.status,
  );
  return (
    <div className="history_item_short">
      <div
        className={`item counter counter_${
          status === 'open' ? 'active' : 'inactive'
        }`}
      >
        <p className={`history_item_long_text ${status === 'open' && 'black'}`}>
          <Timer changeStatus={(s) => setStatus(s)} endDate={item?.endDate} />
        </p>
      </div>
      <div className="item">
        <p className="history_item_short_text">{item?.coinId}</p>
      </div>
      <div className="item">
        <p className="history_item_short_text">${Math.abs(item?.limitPrice)}</p>
      </div>
      <div className="item">
        {item?.status === 'closed' ? (
          item?.profit !== 0 ? (
            <p className="green">Profit (+{item.profit}$)</p>
          ) : (
            <p className="red">Loss (-{Math.abs(item.limitPrice)}$)</p>
          )
        ) : (
          <p>Pending</p>
        )}
      </div>
    </div>
  );
};

const HistoryItemLong = ({ item, refresh }) => {
  const date = new Date();
  const end = new Date(item?.endDate);
  const [status, setStatus] = React.useState(
    date.getTime() < end.getTime() ? 'open' : item?.status,
  );
  return (
    <div className="history_item_long">
      <div className="item">
        <p className="history_item_long_text">
          {item?.startDate.split('T')[0]}
          <br />
          {item?.startDate.split('T')[1].split('.')[0]}
        </p>
      </div>
      <div
        className={`item counter counter_${
          status === 'open' ? 'active' : 'inactive'
        }`}
      >
        <p className={`history_item_long_text ${status === 'open' && 'black'}`}>
          <Timer changeStatus={(s) => setStatus(s)} endDate={item?.endDate} />
        </p>
      </div>
      <div className="item">
        <p className="history_item_long_text">{item?.coinId}</p>
      </div>
      <div className="item">
        <p className="history_item_long_text">{item?.type}</p>
      </div>
      <div className="item">
        <p className="history_item_long_text">${Math.abs(item?.limitPrice)}</p>
      </div>
      <div className="item">
        {item?.status === 'closed' ? (
          item?.profit !== 0 ? (
            <p className="green">Profit (+{item.profit}$)</p>
          ) : (
            <p className="red">Loss (-{Math.abs(item.limitPrice)}$)</p>
          )
        ) : (
          <p>Pending</p>
        )}
      </div>
    </div>
  );
};

const LongList = ({ history, refresh }) => {
  return (
    <div className="list_body">
      <HistoryListHeaderLong />
      <div className="body">
        {history?.map((item, index) => (
          <HistoryItemLong item={item} key={index} refresh={refresh} />
        ))}
      </div>
    </div>
  );
};

const ShortList = ({ history }) => {
  return (
    <div className="list_body">
      <HistoryListHeaderShort />
      <div className="body">
        {history?.map((item, index) => (
          <HistoryItemShort item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

const TransactiosHistory = ({ history, size, refresh }) => {
  return window.innerWidth <= 900 ? (
    <MobileTransactionList history={history} mt={'mt rad'} />
  ) : (
    <div className="container history_container">
      <>
        <div className="header">
          <h1 className="title">Your Operations</h1>
        </div>
        <div
          className="history_list"
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flex: 1,
            justifyContent:
              history && history.length > 0 ? 'flex-start' : 'center',
            justifyContent:
              history && history.length > 0 ? 'flex-start' : 'center',
          }}
        >
          {history && history.length > 0 ? (
            size === 'short' ? (
              <ShortList history={history} refresh={refresh} />
            ) : (
              <LongList history={history} refresh={refresh} />
            )
          ) : (
            <div className="empty_state">
              <h1 className="no_history_indicator">
                It seems you don't have any operations yet
              </h1>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default TransactiosHistory;
