import React from 'react';
import { ChevronLeft } from 'react-feather';
import { Timer } from '@components';

const TransactionModal = ({ transaction, closeModal }) => {
  const date = new Date();
  const end = new Date(transaction?.endDate);
  const [status, setStatus] = React.useState(
    date.getTime() < end.getTime() ? 'open' : transaction?.status,
  );
  return (
    <div className="transaction_modal">
      <div className="backbutton" onClick={closeModal}>
        <ChevronLeft color="white" size={26} />
      </div>
      <div className="modal_list">
        {/* start date */}
        <div className="modal_list_item">
          <p className="modal_list_item_title">Start Date</p>
          <p className="modal_list_item_long_text">
            {transaction?.startDate.split('T')[0]}{' '}
            {transaction?.startDate.split('T')[1].split('.')[0]}
          </p>
        </div>
        {/* token */}
        <div className="modal_list_item">
          <p className="modal_list_item_title">Token</p>
          <p className="modal_list_item_value">{transaction.coinId}</p>
        </div>

        {/* type */}
        <div className="modal_list_item">
          <p className="modal_list_item_title">Type</p>
          <p className="modal_list_item_value">{transaction.type}</p>
        </div>

        {/* amount */}
        <div className="modal_list_item">
          <p className="modal_list_item_title">Amount</p>
          <p className="modal_list_item_value">
            {Math.abs(transaction.limitPrice)}
          </p>
        </div>

        {/* result */}
        <div className="modal_list_item">
          <p className="modal_list_item_title">Result</p>
          <p className="modal_list_item_value">
            {transaction?.status === 'closed' ? (
              transaction?.profit !== 0 ? (
                <p className="green">Profit (+{transaction.profit}$)</p>
              ) : (
                <p className="red">Loss (-{Math.abs(transaction.loss)}$)</p>
              )
            ) : (
              <p>Pending</p>
            )}
          </p>
        </div>

        {/* withdrawn */}
        {/* <div className="modal_list_item">
          <p className="modal_list_item_title">Withdrawn?</p>
          <p className="modal_list_item_value">
            {transaction?.withdrawn ? "Yes" : "No"}
          </p> */}
        {/* </div> */}

        {/* timer */}
      </div>
      <div
        className={`modal_list_item counter_item counter_${
          status === 'open' ? 'active' : 'inactive'
        }`}
      >
        <p className="modal_list_item_title">Time left</p>
        <p className={`history_item_long_text ${status === 'open' && 'black'}`}>
          <Timer
            changeStatus={(s) => setStatus(s)}
            endDate={transaction?.endDate}
          />
        </p>
      </div>
    </div>
  );
};

export default TransactionModal;
