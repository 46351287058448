import React, { useEffect } from 'react';
import useWatchlist from '@hooks/useWatchList';
import { TrendingDown, TrendingUp, X } from 'react-feather';
import { formatPrice } from '@utils/utils.astro';
import { LoaderContext } from '../../../App';
import { LoaderOverlay } from '@components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false, // Hide legend
    },
    title: {
      display: false, // Hide title
    },
  },
  scales: {
    x: {
      display: false, // Hide x-axis
    },
    y: {
      display: false, // Hide y-axis
    },
  },
};

const WatchListHeader = () => {
  return (
    <div className="coins_header">
      <p className="coin_header_text">icon</p>
      <p className="coin_header_text">Name</p>
      <p className="coin_header_text">Symbol</p>
      <p className="coin_header_text">price change 7d</p>
      <p className="coin_header_text">price change 24H</p>
      <p className="coin_header_text">Market Cap</p>
      <p className="coin_header_text">Price Chart 7d</p>
      <p className="coin_header_text">Action</p>
    </div>
  );
};

const MobileWatchListHeader = () => {
  return (
    <div className="coins_header">
      <p className="coin_header_text">icon</p>
      <p className="coin_header_text">Name</p>
      <p className="coin_header_text">Price Chart 7d</p>
    </div>
  );
};

const CoinListItem = ({ coin, removeCoin }) => {
  const [chart, setChart] = React.useState(null);
  const { setLoading, loading } = React.useContext(LoaderContext);
  const [preview, setPreview] = React.useState(false);

  useEffect(() => {
    const options = {
      method: 'GET',
      headers: { 'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=' },
    };

    setLoading(true);
    fetch(
      `https://openapiv1.coinstats.app/coins/${coin?.id}/charts?period=1w`,
      options,
    )
      .then((response) => response.json())
      .then((response) => {
        setChart({
          labels: response?.map((data, index) => index + 1),
          datasets: [
            {
              fill: true,
              label: `${coin?.name} ($)`,
              data: response?.map((data) => data[1]),
              borderColor:
                coin.priceChange1w >= 0
                  ? 'rgba(45, 194, 78, 1)'
                  : 'rgba(249, 44, 44, 1)',
              backgroundColor: (context) => {
                const gradient = context.chart.ctx.createLinearGradient(
                  0,
                  0,
                  0,
                  context.chart.height,
                );
                gradient.addColorStop(
                  0,
                  coin.priceChange1w >= 0
                    ? 'rgba(45, 194, 78, .1)'
                    : 'rgba(249, 44, 44, .1)',
                ); // start color
                gradient.addColorStop(
                  1,
                  coin.priceChange1w >= 0
                    ? 'rgba(45, 194, 78, .1)'
                    : 'rgba(249, 44, 44, .1)',
                ); // end color
                return gradient;
              },
              pointRadius: 0,
              borderWidth: 1,
            },
          ],
        });
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [coin?.id]);

  return window.innerWidth > 900 ? (
    <div className="coin">
      {loading && <LoaderOverlay />}
      <img src={coin?.icon} alt={coin?.name} className="coin_icon" />
      <p className="coin_text">{coin?.name}</p>
      <p className="coin_text">{coin?.symbol}</p>
      <div className="price_change">
        <p
          className={`coin_text ${
            coin?.priceChange1w < 0 ? 'error' : 'success'
          }`}
        >
          {coin?.priceChange1w}
        </p>
        {coin?.priceChange1w < 0 ? (
          <TrendingDown className="icon" color="#F92C2C" />
        ) : (
          <TrendingUp className="icon" color="#2DC24E" />
        )}
      </div>
      <div className="price_change">
        <p
          className={`coin_text ${
            coin?.priceChange1d < 0 ? 'error' : 'success'
          }`}
        >
          {coin?.priceChange1d}
        </p>
        {coin?.priceChange1d < 0 ? (
          <TrendingDown className="icon" color="#F92C2C" />
        ) : (
          <TrendingUp className="icon" color="#2DC24E" />
        )}
      </div>

      <p className="coin_text">{formatPrice(coin?.marketCap?.toFixed(0))}</p>
      <div className="coin_chart_wrapper">
        {chart && (
          <Line
            className="coin_chart"
            options={options}
            data={chart}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>

      <button className="cta" onClick={() => removeCoin(coin)}>
        Remove
      </button>
    </div>
  ) : (
    <div className="coin">
      {loading && <LoaderOverlay />}
      <img src={coin?.icon} alt={coin?.name} className="coin_icon" />
      <p className="coin_text">{coin?.name}</p>
      <div className="coin_chart_wrapper">
        {chart && (
          <Line
            className="coin_chart"
            options={options}
            data={chart}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
    </div>
  );
};

const WatchListBody = ({ coins, removeCoin }) => {
  return (
    <div className="modal_body">
      <div className="coins_container">
        {coins?.map((coin) => (
          <CoinListItem key={coin?.id} coin={coin} removeCoin={removeCoin} />
        ))}
      </div>
    </div>
  );
};

const WatchlistModal = ({ closeModal, coins, addCoin }) => {
  if (!coins || coins?.length < 1) return <></>;

  return (
    <div className="modal_wrapper" onClick={closeModal}>
      <div className="modal_container" onClick={(e) => e.stopPropagation()}>
        <div className="modal">
          <div className="modal_header">
            <h1 className="title">Add coins to your watchlist</h1>
            <div className="close_modal" onClick={closeModal}>
              <p className="cta_text">Close</p>
              <X onClick={closeModal} />
            </div>
          </div>
          <div className="coins_header">
            <p className="coin_header_text">icon</p>
            <p className="coin_header_text">Name</p>
            {window.innerWidth > 900 && (
              <>
                <p className="coin_header_text">Symbol</p>
                <p className="coin_header_text">price change 24H</p>
                <p className="coin_header_text">price change 1H</p>
                <p className="coin_header_text">Market Cap</p>
              </>
            )}
            <p className="coin_header_text">Action</p>
          </div>
          <div className="modal_body">
            <div className="coins_container">
              {coins?.map((coin, index) => (
                <div className="coin" key={index}>
                  <img
                    src={coin?.icon}
                    alt={coin?.name}
                    className="coin_icon"
                  />
                  <p className="coin_text">{coin?.name}</p>
                  {window.innerWidth > 900 && (
                    <>
                      <p className="coin_text">{coin?.symbol}</p>
                      <div className="price_change">
                        <p
                          className={`coin_text ${
                            coin?.priceChange1d < 0 ? 'error' : 'success'
                          }`}
                        >
                          {coin?.priceChange1d}
                        </p>
                        {coin?.priceChange1d < 0 ? (
                          <TrendingDown className="icon" color="#F92C2C" />
                        ) : (
                          <TrendingUp className="icon" color="#2DC24E" />
                        )}
                      </div>
                      <div className="price_change">
                        <p
                          className={`coin_text ${
                            coin?.priceChange1h < 0 ? 'error' : 'success'
                          }`}
                        >
                          {coin?.priceChange1h}
                        </p>
                        {coin?.priceChange1h < 0 ? (
                          <TrendingDown className="icon" color="#F92C2C" />
                        ) : (
                          <TrendingUp className="icon" color="#2DC24E" />
                        )}
                      </div>

                      <p className="coin_text">
                        {formatPrice(coin?.marketCap?.toFixed(0))}
                      </p>
                    </>
                  )}
                  <button className="cta" onClick={() => addCoin(coin?.id)}>
                    Add
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Watchlist = () => {
  const [modal, setModal] = React.useState(false);
  const { watchList, loading, coins, addToWatchList, removeFromWatchList } =
    useWatchlist();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const [watchedCoins, setWatchedCoins] = React.useState([]);

  const removeCoin = async (coin) => {
    await removeFromWatchList(coin);
    window.location.reload();
  };

  useEffect(() => {
    if (watchList && watchList.length > 0) {
      const watched = coins.filter(({ id }) => {
        return watchList.filter((watch) => watch.coinId === id).length > 0;
      });
      //   add watchlist _id to each coin
      watched.forEach((coin) => {
        const watch = watchList.filter((watch) => watch.coinId === coin.id)[0];
        coin._id = watch?._id;
      });
      setWatchedCoins(watched);
    }
  }, [watchList, coins]);

  return (
    <div className="watchlist_container">
      {loading && <LoaderOverlay />}
      {modal && (
        <WatchlistModal
          closeModal={() => setModal(false)}
          coins={coins}
          addCoin={(id) => {
            addToWatchList(id);
            setModal(false);
          }}
        />
      )}
      <div className="header">
        <div className="wallet_info">
          <p className="title">Your coin list</p>
        </div>
        <div className="heading_cta_container">
          <button className="cta" onClick={() => setModal(true)}>
            Add more coins
          </button>
        </div>
      </div>

      {watchList.length >= 1 ? (
        <div className="watchlist_table_container">
          {window.innerWidth > 900 ? (
            <WatchListHeader />
          ) : (
            <MobileWatchListHeader />
          )}
          <WatchListBody coins={watchedCoins} removeCoin={removeCoin} />
        </div>
      ) : (
        <div className="holdings">
          <div className="container">
            <div className="empty_state">
              <h1 className="empty_state_title">
                It looks like you don't have any watchlist yet
              </h1>
              <button
                className="empty_state_cta"
                onClick={() => setModal(true)}
              >
                Add coins
              </button>
            </div>
            <div className="holdings_table_contaienr"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Watchlist;
