import React from 'react';
import { HashLoader } from 'react-spinners';

const LoaderOverlay = () => {
  return (
    <div className="loader_overlay">
      <HashLoader color="#77ED91" />
    </div>
  );
};

export default LoaderOverlay;
