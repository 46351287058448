import React from 'react';
import { Nav } from '@components';
import { useAuth } from '@hooks/useAuth';
import { LoaderOverlay } from '@components';

const Reset = () => {
  document.title = 'Webtrade - Reset Password';
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);
  const [sent, setSent] = React.useState(false);
  const { sendResetMail, user, loading } = useAuth();

  if (user) {
    return window.location.replace('/dashboard/?view=dashboard');
  }

  const sendMail = async (e) => {
    e.preventDefault();
    if (!email) return setError('Please enter your email');
    const data = await sendResetMail(email);
    if (data?.error) return setError(data.error);
    setSent(true);
  };

  return (
    <section className="auth_page">
      {loading && <LoaderOverlay />}
      <Nav hide="signup" />
      <main className="auth_container">
        {!sent && (
          <div className="auth_info">
            <h1 className="auth_title">Forgot your password?</h1>
            <p className="auth_description">
              It's okay, enter your email and we'll send you a magic link!
            </p>
          </div>
        )}
        <form className="auth_form">
          {!sent && (
            <>
              <div className="auth_form_group">
                <label htmlFor="email">Email</label>
                <input
                  onFocus={() => setError(null)}
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter your email"
                />
              </div>
              {error && <p className="error">{error}</p>}
              <button onClick={sendMail}>send</button>
            </>
          )}
          {sent && (
            <p className="auth_description green">
              Please check your email for a magic link!
              <br />
              It may take a few minutes to arrive.
            </p>
          )}
        </form>
      </main>
    </section>
  );
};

export default Reset;
